import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: [],
        },
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/LayoutBuilder.vue"),
        meta: {
          pageTitle: "Layout Builder",
          breadcrumbs: ["Layout"],
        },
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () => import("@/views/perfil/Perfil.vue"),
        meta: {
          pageTitle: "Dados Básicos",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/trabalhos",
        name: "trabalhos",
        component: () => import("@/views/trabalhos/Lista.vue"),
        meta: {
          pageTitle: "Trabalhos (Publis)",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/social",
        name: "social",
        component: () => import("@/views/social/Lista.vue"),
        meta: {
          pageTitle: "Redes Sociais",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/links",
        name: "links",
        component: () => import("@/views/links/Lista.vue"),
        meta: {
          pageTitle: "Links",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/contato",
        name: "contato",
        component: () => import("@/views/contato/Lista.vue"),
        meta: {
          pageTitle: "Informações de Contato",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/upgrade",
        name: "upgrade",
        component: () => import("@/views/upgrade/Upgrade.vue"),
        meta: {
          pageTitle: "Upgrade",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/faturamento",
        name: "faturamento",
        component: () => import("@/views/upgrade/Manage.vue"),
        meta: {
          pageTitle: "Faturamento",
          breadcrumbs: ["Dashboards"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Login",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Cadastro",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
